<template>
  <div>
    <h1>Data Tester</h1>
    <p> {{ data }} </p>

  </div>
</template>

<script>
import {url_name} from './../../../global-assets/js/firebase.service'
export default {
name: "playground",
data () {
  return {
    data : 'plain_data'
  }
},
firestone () {
  return {
     data : url_name
  }
},

}
</script>

<style scoped>

</style>